import { useState } from "react";
import { useNavigate } from "react-router-dom";
import pig from "../../images/pig.png";
import { useSelector } from "react-redux";

export default function PaymentFail() {
    const navigate = useNavigate();
    const customer = useSelector((state: any) => state.customer);

    return (
        <div>
            <section className="h-100n">
                <div className="d-flex flex-column space-between h-100 text-center">
                    <div className="pd-22">
                        <p className="mb-30 fs-15 medium">{""}</p>
                        <div className="circle-progress mb-30" style={{background: "linear-gradient(to bottom, #585C65 50%, #E76B84 50%"}}>
                            <div>
                                <img src={pig} alt="" />
                            </div>
                        </div>
                        <p className="mb-30 fs-15 medium">{customer.orderName}</p>
                        <p className="mb-10 fs-25 bold color-red">결제 실패</p>
                        <p className="fs-25">{}</p>
                        
                    </div>
                    <p className="medium color-gray">결제가 거부되었습니다<br /> 결제정보를 확인해주세요</p>
                    <div className="btn-area">
                        <button className="btn btn-pink" onClick={()=>navigate(-1)}>확인</button>
                    </div>
                </div>
            </section>
        </div>
    )
}