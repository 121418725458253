import React, { useState, useEffect } from "react";
import ReactModal from "react-modal";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { addComma } from "../../utils/StringFormat";
import MyOrderDetailPreview from "./MyOrderDetailPreview";
import { useDispatch } from "react-redux";

const MyOrderDetail = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const user = useSelector((state: any) => state.user);
    const location = useLocation();
    let isQuestion = useSelector((state: any) => state.question);
    let [showModal, setShowModal] = useState<boolean>(false);


    let arr: Array<any> = [];
    const [myOrderDetails, setMyOrderDetails] = useState(arr);

    const [selectedSort, setSelectedSort] = useState("전체");

    const GetMyOrder = async () => {
        const res = await fetch(process.env.REACT_APP_SERVER_URL + `/myshopping/order/${location.state.orderID}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
        })
        const data = await res.json();
        setMyOrderDetails(data);
    }

    useEffect(() => {
        GetMyOrder();

        if(isQuestion.bool === true){
            setShowModal(true);
            setTimeout(() => {
                dispatch({ type: "SET_QUESTION", payload: {bool: false} });
                setShowModal(false);
            }, 2000);
        }
    }, [])



    return (
        <div>
            <header className="sub">
                <a className="btn-prev" onClick={()=>navigate(-1)}><span>이전</span></a>
                <h1>주문상세</h1>
            </header>
            <section className="full-sub">
                <div className="shop-detail">
                    <div className="d-flex space-between pd-22 align-center">
                        <div className="medium">
                            <p>No.{myOrderDetails.length > 0 ? myOrderDetails[0].ORDER_ID : ""}</p>
                            <p>{myOrderDetails.length > 0 ? myOrderDetails[0].CREATE_DT.substring(0, 10) : ""}</p>
                        </div>
                        <p className="color-gray">내역삭제</p>
                    </div>
                </div>

                {myOrderDetails.map((item: any) => (
                    <MyOrderDetailPreview {...item} key={item.ORDER_DETAIL_ID} />
                ))}

                <div className="shop-detail">
                    <div className="pd-side pd-tb-15">
                        <p className="fs-15 bold">배송지 정보</p> 
                    </div>
                    <div className="inner bd-top pt-10">
                        <dl>
                            <dt>이름</dt>
                            <dd>{myOrderDetails.length > 0 ? myOrderDetails[0].NAME_TO : ""}</dd>
                        </dl>
                        <dl>
                            <dt>전화번호</dt>
                            <dd>{myOrderDetails.length > 0 ? myOrderDetails[0].MOBILE : ""}</dd>
                        </dl>
                        <dl>
                            <dt>주소</dt>
                            <dd>{myOrderDetails.length > 0 ? myOrderDetails[0].ROAD_ADDR : ""}<br/>{myOrderDetails.length > 0 ? myOrderDetails[0].ADDRESS : ""}</dd>
                        </dl>
                        <dl>
                            <dt>배송메모</dt>
                            <dd>{myOrderDetails.length > 0 ? myOrderDetails[0].DELIVERY_MESSAGE : ""}</dd>
                        </dl>
                    </div>
                </div>
                
                
                <div className="shop-detail">
                    <div className="pd-side pd-tb-15">
                        <p className="fs-15 bold">결제금액</p> 
                    </div>
                    <div className="pd-side bd-top">
                        <dl className="d-flex space-between pd-tb-10">
                            <dt className="color-gray">상품금액</dt>
                            <dd>{myOrderDetails.length > 0 ? addComma(myOrderDetails[0].ORIGINAL_AMOUNT) : ""}원</dd>
                        </dl>
                        <dl className="d-flex space-between pd-tb-10">
                            <dt className="color-gray">배송비</dt>
                            <dd>{myOrderDetails.length > 0 ? addComma(myOrderDetails[0].DELIVERY_AMOUNT) : ""}원</dd>
                        </dl>
                        <dl className="d-flex space-between pd-tb-10">
                            <dt className="color-gray">할인금액</dt>
                            <dd>-{myOrderDetails.length > 0 ? addComma(myOrderDetails[0].DISCOUNT_AMOUNT) : ""}원</dd>
                        </dl>
                        <dl className="d-flex space-between pd-tb-10">
                            <dt className="fs-14 bold">총 결제 금액</dt>
                            <dd className="fs-14 bold">{myOrderDetails.length > 0 ? addComma(myOrderDetails[0].TOTAL_AMOUNT) : ""}원</dd>
                        </dl>
                    </div>
                </div>
                
                <div className="shop-detail">
                    <div className="pd-side pd-tb-15">
                        <p className="fs-15 bold">결제 수단</p> 
                    </div>
                    <div className="pd-side pd-15 bd-top d-flex space-between">
                        <p className="medium">{myOrderDetails.length > 0 ? addComma(myOrderDetails[0].PAY_WITH) : ""}</p>
                        <p className="bold">{myOrderDetails.length > 0 ? addComma(myOrderDetails[0].TOTAL_AMOUNT) : ""}원</p>
                    </div>
                </div>

            </section>
            <ReactModal isOpen={showModal} onRequestClose={() => setShowModal(false)} className="modal" overlayClassName="overlay" ariaHideApp={false}>
                <div className="balance-alert">
                    후기가 등록되었습니다
                </div>
            </ReactModal>
        </div>
    )
}

export default MyOrderDetail;