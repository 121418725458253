import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import iconMy01 from "../../images/ico_my01.png"
import iconMy02 from "../../images/ico_my02.png"
import iconMy03 from "../../images/ico_my03.png"


const MyShopping = () => {
    const navigate = useNavigate();
    const user = useSelector((state: any) => state.user);

    return (
        <div>
            <header className="sub">
                <a className="btn-prev" onClick={()=>navigate(-1)}><span>이전</span></a>
                <h1>내 쇼핑정보</h1>
            </header>
            <section className="full-sub">
                <div className="pd-side">
                    <div className="bg-gray d-flex space-between pd-15 mb-20">
                        <p className="fs-15 bold">{user.KAKAO_NICK}</p>
                        <p>{user.EMAIL ? user.EMAIL : ""}</p>
                    </div>
                    <div className="d-flex space-around pd-tb-20">
                        <div onClick={() => navigate('/myshopping/order')}>
                            <p className="text-center"><img src={iconMy01} alt="" style={{width: "19px"}}/></p>
                            <p className="fs-12 bold mt-10">주문·배송</p>
                        </div>
                        <div onClick={() => navigate('/myreview')}>
                            <p className="text-center"><img src={iconMy02} alt="" style={{width: "19px"}}/></p>
                            <p className="fs-12 bold mt-10">후기</p>
                        </div>
                        <div onClick={() => navigate('/mybank/inquiry')}>
                            <p className="text-center"><img src={iconMy03} alt="" style={{width: "19px"}}/></p>
                            <p className="fs-12 bold mt-10">고객센터</p>
                        </div>
                    </div>
                </div>   
            </section>
        </div>
    )
}

export default MyShopping;