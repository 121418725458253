import React, { FC, useState, useEffect } from "react";
import icoMinus from "../../images/ico_minus.png";
import icoPlus from "../../images/ico_plus.png";
import { addComma } from "../../utils/StringFormat";
import { GetThumbnail } from "../../utils/ImageHandler";
import imgList from "../../images/img_list.png";
import { useDispatch } from "react-redux";
import { addItem, removeItem } from "../../store/CartReducer";
import defaultImage from "../../images/default.png";

interface CartItemProps {
    SHOPPING_CART_ID : number;
    PRODUCT_ID: number;
    PRODUCT_NM: string;
    PRICE: number;
    DISCOUNT_PRICE: number;
    DELIVERY_PRICE: number;
    COUNT: number;
    FARM_NM: string;
    CHECK: boolean;
    ClickPlus: (id: number) => void;
    ClickMinus: (id: number) => void;
    ClickDelete: (id: number) => void;
}

const CartItem: FC<CartItemProps> = (props) => {
    const dispatch = useDispatch();
    const [imagePath, setImagePath] = useState<string>("");
    const [check, setCheck] = useState<boolean>(props.CHECK);
    let [count, setCount] = useState<number>(props.COUNT);


    useEffect(() => {
        GetThumbnail("TB_ORDER_PRODUCT", props.PRODUCT_ID).then((res) => setImagePath(res));
    }, []);

    function handleCheck (): void {
        if (check) {
            dispatch(removeItem(props.SHOPPING_CART_ID))
        }
        else {
            dispatch(addItem({
                SHOPPING_CART_ID: props.SHOPPING_CART_ID, 
                PRODUCT_ID: props.PRODUCT_ID,
                PRODUCT_NM: props.PRODUCT_NM,
                PRICE: props.PRICE,
                DISCOUNT_PRICE: props.DISCOUNT_PRICE,
                DELIVERY_PRICE: props.DELIVERY_PRICE, 
                COUNT: props.COUNT,
                FARM_NM: props.FARM_NM}))
        }
        setCheck(!check);
    }

    function handlePlus () {
        setCount(++count);
        props.ClickPlus(props.SHOPPING_CART_ID);
    }

    function handleMinus () {
        if(count > 1) {
            setCount(--count);
            props.ClickMinus(props.SHOPPING_CART_ID);
        }
    }

    function handleDelete () {
        props.ClickDelete(props.SHOPPING_CART_ID);
    }

    return (

        <div className="order-list"> 
            <div className="form-checkbox cart-checkbox mb-0">
                <input type="checkbox" id={props.SHOPPING_CART_ID.toString()} className="check" 
                checked={check} onChange={handleCheck}/>
                <label htmlFor={props.SHOPPING_CART_ID.toString()}></label>
            </div>
            <div className="product-data">
                <div className="d-flex">
                    <p className="mr-10">
                        <img src={imagePath ? imagePath : defaultImage} alt="" style={{width: "60px"}} />

                    </p>
                    <div>
                        <div>
                            <p className="fs-15 medium">{props.PRODUCT_NM}</p>
                            <p className="fs-11 color-gray mt-5">{addComma(props.DELIVERY_PRICE)}｜일반택배</p>
                        </div>
                    </div>
                </div>
                <div className="cart-qty">
                    <div className="spiner-wrap">
                    <a className="minus disabled" onClick={handleMinus}><img src={icoMinus} alt="" /></a> 
                    <div className="number">{count}</div>
                    <a className="plus" onClick={handlePlus}><img src={icoPlus} alt="" /></a>
                    </div>
                    <p className="price"><span>{addComma(props.PRICE * props.COUNT)}원</span>{addComma(props.DISCOUNT_PRICE * props.COUNT)}원</p>
                </div>
                <a className="btn-delete" onClick={handleDelete}><span>삭제</span></a>
            </div>
        </div>
    );
};

export default CartItem;