export interface FundBill {
    PIG_NM: string;
    FUNDING_ID: number;
    BIDDING_AMOUNT: number;
    BIDDING_COUNT: number;
}

export interface FundBillAction {
    type: string;
    payload: FundBill | null;
}

const BiddingReducer = (state : FundBill | null = null, action: FundBillAction) : FundBill | null => {
    switch (action.type) {
        case 'SET_BID':
            return action.payload;
        default:
            return state;
    }
}

export default BiddingReducer;