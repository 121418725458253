import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import MyOrderPreview from "./MyOrderPreview";

const MyOrder = () => {
    const navigate = useNavigate();
    const user = useSelector((state: any) => state.user);

    let arr: Array<any> = [];
    const [myOrders, setMyOrders] = useState(arr);

    const [selectedSort, setSelectedSort] = useState("전체");

    const GetMyOrder = async () => {
        const res = await fetch(process.env.REACT_APP_SERVER_URL + "/myshopping/order", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                userID : user.USER_ID
            })
        })
        const data = await res.json();

        setMyOrders(data);
    }

    useEffect(() => {
        GetMyOrder();
    }, [])

    return (
        <div>
            <header className="sub">
                <a className="btn-prev" onClick={()=>navigate(-1)}><span>이전</span></a>
                <h1 className="fs-20">주문·배송</h1>
                <div className="right">
                    <a className="btn-cart btn-ico"><span>장바구니</span></a>
                    <a className="btn-my btn-ico"><span>마이페이지</span></a>
                </div>
            </header>
            <section className="full-sub">
                <div className="pd-side">
                    <div className="sort-group">
                        <div>
                            <a className={selectedSort === "전체" ? "active" : ""} onClick={() => setSelectedSort("전체")}>전체</a>
                            <a className={selectedSort === "배송중" ? "active" : ""} onClick={() => setSelectedSort("배송중")}>배송중</a>
                            <a className={selectedSort === "배송완료" ? "active" : ""} onClick={() => setSelectedSort("배송완료")}>배송완료</a>
                            <a className={selectedSort === "취소/교환/반품" ? "active" : ""} onClick={() => setSelectedSort("취소/교환/반품")}>취소/교환/반품</a>
                        </div>
                    </div>
                </div>

                {myOrders.filter((order) => order.ORDER_STATUS === selectedSort || selectedSort === "전체").map((item: any) => (
                    <MyOrderPreview {...item} key={item.ORDER_DETAIL_ID} />
                ))}
            </section>
        </div>
    )
}

export default MyOrder;