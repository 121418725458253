import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CartItem from "./CartItem";
import CartTotal from "./CartTotal";
import { useDispatch } from "react-redux";
import { CartType, initCart } from "../../store/CartReducer";

const Cart = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const user = useSelector((state: any) => state.user);
    const [items, setItems] = useState<any>([]);
    const [farms, setFarms] = useState<any>([]);
    const [checkAll, setCheckAll] = useState<boolean>(true);

    const GetCart = async () => {
        const res = await fetch(process.env.REACT_APP_SERVER_URL + `/cart/${user.USER_ID}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        });

        const data = await res.json();
        setItems(data);
        const uniqueIds = new Set(data.map((item: any) => item.FARM_NM));
        setFarms(Array.from(uniqueIds));
        SetCartRedux(data);
    }

    useEffect(() => {
        if(checkAll) {
            SetCartRedux(items);
        } else {
            SetCartRedux([]);
        }
    }, [checkAll]);

    useEffect(() => {
        GetCart();
    }, []);

    function SetCartRedux(items: any) {
        let cartItem : CartType[] = [];
        items.forEach((element: any) => {
            let item : CartType = {
                SHOPPING_CART_ID: element.SHOPPING_CART_ID,
                PRODUCT_ID: element.PRODUCT_ID,
                PRODUCT_NM: element.PRODUCT_NM,
                PRICE: element.PRICE,
                DISCOUNT_PRICE: element.DISCOUNT_PRICE,
                DELIVERY_PRICE: element.DELIVERY_PRICE,
                COUNT: element.COUNT,
                FARM_NM: element.FARM_NM,
            }
            cartItem.push(item);
        });
        dispatch(initCart(cartItem));
    }

    const EmptyCart = () => {
        if(items.length > 0) return null;
        
        return( 
            <section className="one-page">
                <div>
                    <div className="blank-txt">
                    <p className="fs-15 bold color-gray text-center">장바구니에 담긴 상품이 없습니다</p>
                    </div>
                    <div>
                        <a className="btn btn-gray mb-20">상품을 담아주세요</a>
                    </div>
                </div>
            </section>
        )
    }

    interface ItemByFarmProps {
        farmName: string;
    }
    const ItemByFarm = ({farmName} : ItemByFarmProps) => {
        const farmItems = items.filter((item: any) => item.FARM_NM === farmName); 

        return(
            <div className="farm-detail pd-0">
                <div className="order-list-title">
                    <h2 className="fs-15">{farmItems[0]?.FARM_NM}</h2>
                </div>
                {farmItems.map((item: any) => (
                    <CartItem {...item} key={item.PRODUCT_ID} 
                    CHECK={checkAll}
                    ClickMinus={()=>handleMinus(item.SHOPPING_CART_ID)} 
                    ClickPlus={()=>handlePlus(item.SHOPPING_CART_ID)}
                    ClickDelete={()=>DeleteItem(item.SHOPPING_CART_ID)}/>
                ))}
            </div>
        )
    }

    async function DeleteItem (id: number) {
        const res = await fetch(process.env.REACT_APP_SERVER_URL + `/cart/delete/${id}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        });

        await res.json();
        GetCart();
    }

    function DeleteCheckedItem () {
        items.forEach((item: any) => {
            DeleteItem(item.SHOPPING_CART_ID);
        });
        GetCart();
    }


    const ItemCart = () => {
        if(items.length < 1) return null;

        return(
            <section>
                <div>
                    <div className="farm-detail d-flex space-between">
                        <div className="form-checkbox cart-checkbox mb-0">
                            <input type="checkbox" id="check1" className="check" checked={checkAll} onChange={()=>setCheckAll(!checkAll)}/>
                            <label htmlFor="check1"></label><p className="label medium">전체선택</p>
                        </div>
                        <a className="color-gray" onClick={DeleteCheckedItem}>선택삭제</a>
                    </div>

                    {farms.map((farm: string) => (
                        <ItemByFarm farmName={farm} key={farm}/>
                    ))}

                    <CartTotal farms={farms}/>
                </div>
            </section>
        )
    }

    async function handlePlus (id: number) {
        let res = await fetch(process.env.REACT_APP_SERVER_URL + `/cart/${id}/plus/${user.USER_ID}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        });
        let data = await res.json();
        setItems(data);
        SetCartRedux(data);

    }
        

    async function handleMinus (id: number) {
        let res = await fetch(process.env.REACT_APP_SERVER_URL + `/cart/${id}/minus/${user.USER_ID}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        });

        let data = await res.json();
        setItems(data);
        SetCartRedux(data);
    }


    return (
        <div>
            <header className="sub">
                <a className="btn-prev" onClick={()=>navigate(-1)}><span>이전</span></a>
                <h1>장바구니</h1>
            </header>
            {EmptyCart()}
            {ItemCart()}
        </div>
    );
};

export default Cart;