import { Route, Routes } from 'react-router-dom';
import Login from './routes/Login';
import Shop from './routes/Shop';
import './css/default.css';
import './css/font.css';
import './css/custom.css';
import './css/swiper-bundle.min.css'
import { useState } from 'react';
import Kakao from './routes/Kakao';
import ShopDetail from './components/shop/ShopDetail';
import ReviewList from './components/shop/ReviewList';
import Inquery from './components/shop/Inquery';
import InqueryNotice from './components/shop/InqueryNotice';
import Cart from './components/shop/Cart';
import MyShopping from './components/shop/MyShopping';
import MyOrder from './components/shop/MyOrder';
import MyOrderDetail from './components/shop/MyOrderDetail';
import MyReview from './components/shop/MyReview';
import RateReview from './components/shop/RateReview';
import CommentReview from './components/shop/CommentReview';



import ShippingList from './components/shop/ShippingList';
import Payment from './components/shop/Payment';

import ShippingAdd from './components/shop/ShippingAdd';
import ShippingEdit from './components/shop/ShippingEdit';


import PaymentSuccess from './components/shop/PaymentSuccess';
import PaymentFail from './components/shop/PaymentFail';


function App() {
  const [activeLink, setActiveLink] = useState("menu1");
  const [isActive, setIsActive] = useState(false);

  const pageProps = {
    setActiveLink: setActiveLink,
    setIsActive: setIsActive
  }

  return (
    <div className='main-wrap'>
      <Routes>
        <Route path="/" element={<Login />}></Route>
        <Route path="/oauth" element={<Kakao />}></Route>
        <Route path="/shop" element={<Shop {...pageProps} />}></Route>



        <Route path="/myshopping" element={<MyShopping />}></Route>
        <Route path="/myshopping/order" element={<MyOrder />}></Route>
        <Route path="/myshopping/order/detail" element={<MyOrderDetail />}></Route>
        <Route path="/myreview/" element={<MyReview />}></Route>
        <Route path="/review/rate" element={<RateReview />}></Route>
        <Route path="/review/comment" element={<CommentReview />}></Route>


        <Route path="/shop/detail" element={<ShopDetail />}></Route>
        <Route path="/reviews" element={<ReviewList />}></Route>
        <Route path="/inquery" element={<Inquery />}></Route>
        <Route path="/inquery/notice" element={<InqueryNotice />}></Route>
        <Route path="/cart" element={<Cart />}></Route>

        <Route path="/shop/ship" element={<ShippingList />}></Route>
        <Route path="/shop/payment" element={<Payment />}></Route>
        <Route path="/shop/payment/success" element={<PaymentSuccess />}></Route>
        <Route path="/shop/payment/fail" element={<PaymentFail />}></Route>
        <Route path="/shop/ship/add" element={<ShippingAdd />}></Route>
        <Route path="/shop/ship/edit" element={<ShippingEdit />}></Route>
      </Routes>
    </div>

  );
}

export default App;
