import React, { useRef, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

const Inquery = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const textAreaRef = useRef<HTMLTextAreaElement>(null);
    const productID = useSelector((state: any) => state.shopSelect);
    const [buttonColor, setButtonColor] = useState<string>("btn btn-gray mb-20");
    const [selected, setSelected] = useState<string>("선택");

    const HandleSubmit = async () => {
        if (selected === "선택") {
            alert("문의 유형을 선택해주세요.");
            return;
        }

        dispatch({ type: "SET_QUESTION", payload: {bool: true} });
        const response = await fetch(process.env.REACT_APP_SERVER_URL + "/inquery", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                "productID": productID.productID,
                "content": textAreaRef.current?.value,
                "category": selected,
            }),
        });
        const data = await response.json();
        navigate(-2);
    };

    const HandleChange = () => {
        if (textAreaRef.current?.value === "") {
            setButtonColor("btn btn-gray mb-20");
        } else {
            setButtonColor("btn btn-pink mb-20");
        }
    };

    const ChangeSelectOption = (e: any) => {
        setSelected(e.target.value);
    };

    return (
        <div>
            <header className="sub">
                <a className="btn-prev" onClick={()=>navigate(-1)}><span>이전</span></a>
                <h1>상품 문의 작성</h1>
            </header>
            
            <section className="one-page">
                <div>
                    <div>
                        <div className="input-query">
                            <select title="selectbar" value={selected} onChange={ChangeSelectOption}>
                                <option value="상품">상품 관련 문의</option>
                                <option value="배송">배송 관련 문의</option>
                                <option value="반품">반품 관련 문의</option>
                                <option value="교환">교환 관련 문의</option>
                                <option value="환불">환불 관련 문의</option>
                                <option value="기타">기타 관련 문의</option>
                            </select>
                            <textarea title="textbox" name="" id="" cols={30} rows={10} ref={textAreaRef} onChange={HandleChange}></textarea>
                        </div>
                        <div className="about-query mt-15">
                                <p>문의내용에 대한 답변은 [내 쇼핑정보 &gt; 나의 문의내역]에서 확인 가능합니다.</p>
                                <p>상품과 관련 없거나 부적합한 내용을 기재하시는 경우, 사전 고지 없이 삭제 또는 차단될 수 있습니다.</p>
                        </div>
                    </div>
                    
                    <div>
                        <a className={buttonColor} onClick={HandleSubmit}>문의하기</a>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default Inquery;