import React, { useRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

const CommentReview = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const user = useSelector((state: any) => state.user);
    const location = useLocation();
    const textAreaRef = useRef<HTMLTextAreaElement>(null);
    const [commentLength, setCommentLength] = useState(0);
    const [buttonColor, setButtonColor] = useState<string>("btn btn-gray mb-20");

    const HandleChange = () => {
        if (textAreaRef.current?.value.length! < 20) {
            setButtonColor("btn btn-gray mb-20");
        } else {
            setButtonColor("btn btn-pink mb-20");
        }

        setCommentLength(textAreaRef.current?.value.length!);
    };

    const HandleSubmit = async () => {
        if (buttonColor.includes("btn-pink")) {
            const res = await fetch(process.env.REACT_APP_SERVER_URL + `/review/add`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    userId : user.USER_ID,
                    productID : location.state.orderDetail.PRODUCT_ID,
                    orderDetailID : location.state.orderDetail.ORDER_DETAIL_ID,
                    rate : location.state.rate,
                    reviewText : textAreaRef.current?.value,
                })
            })
            const data = await res.json();

            dispatch({ type: "SET_QUESTION", payload: {bool: true} });

            navigate(-2);
        }
    }

    useEffect(() => {

    }, [])

    return (
        <div className="review-div">
            <header className="sub">
                <a className="btn-prev" onClick={()=>navigate(-1)}><span>이전</span></a>
                <h1>{location.state.orderDetail.PRODUCT_NM}</h1>
            </header>
            <section className="one-page">
                <div>
                    <div>
                    <div className="d-flex">
                        <p style={{marginRight: "10px"}}>상세한 후기를 써주세요.</p>
                        <p>{commentLength} / 100</p>
                    </div>
                    <div className="input-query">
                        <textarea name="" id="" ref={textAreaRef} cols={30} rows={10} onChange={HandleChange} placeholder="구매하신 아이템의 후기를 20자 이상 남겨주시면 다른 구매자들에게도 도움이 됩니다."></textarea>
                    </div>
                    </div>
                    
                    <div>
                        <a className={buttonColor} onClick={HandleSubmit}>작성 완료</a>
                    </div>
                </div>

            </section>
        </div>
    )
}

export default CommentReview;