import { useEffect, useRef } from "react";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";

const Login = () => {
    const [cookies, setCookie, removeCookie] = useCookies(['user']);
    const navigate = useNavigate();
    
    const kakaoHandler = () => {
        window.location.href = `https://kauth.kakao.com/oauth/authorize?client_id=${process.env.REACT_APP_REST_API_KEY}&redirect_uri=${process.env.REACT_APP_REDIRECT_URI}&response_type=code`;
    }

    useEffect(() => {
        if (cookies.user) {
            navigate('/shop');
        }
    }, [cookies.user]);

    const userID = useRef<HTMLInputElement>(null);
    const userPW = useRef<HTMLInputElement>(null);

    function loginHandler() {
        if (userID.current?.value === "test" && userPW.current?.value === "thisistest") {
            setCookie('user', 50, { path: '/' });
            navigate('/shop');

            return;
        }

        alert("아이디 또는 비밀번호가 일치하지 않습니다.");
    }

    return (
        <div>
            <header className="login">
            </header>
            <section className="d-flex flex-column space-around login-100">
                <div className="login">
                    <h1>로그인</h1>
                    <div className="form-input">
                        <input type="text" placeholder="아이디(이메일)" ref={userID}/>
                    </div>
                    <div className="form-input">
                        <input type="password" placeholder="비밀번호" ref={userPW}/>
                    </div>
                    <button className="btn btn-pink" onClick={loginHandler} >로그인하기</button>
                    <p className="btn-sns-tit">SNS계정으로 로그인하기</p>
                    <div className="btn-sns" onClick={kakaoHandler}></div>
                    <button className="btn btn-outline btn-join">계정이 없으신가요? 간편가입하기</button>
                </div>
                <div>
                    <ul className="find-idpw">
                        <li><a>아이디 (이메일) 찾기</a></li>
                        <li><a>비밀번호 찾기</a></li>
                    </ul>
                </div>
            </section>
        </div>
    );
}

export default Login;
