import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useCookies } from "react-cookie";

const Kakao = () => {
    const [cookies, setCookie, removeCookie] = useCookies(['user']);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [allCheck, setAllCheck] = useState<boolean>(false);
    const [check1, setCheck1] = useState<boolean>(false);
    const [check2, setCheck2] = useState<boolean>(false);
    const [check3, setCheck3] = useState<boolean>(false);
    const [check4, setCheck4] = useState<boolean>(false);

    const [allowNext, setAllowNext] = useState<boolean>(false);

    const [isRegistered, setIsRegistered] = useState<boolean>(false);

    const AllCheckButton = (e: any) => {
        setAllCheck(e.target.checked);
        setCheck1(e.target.checked);
        setCheck2(e.target.checked);
        setCheck3(e.target.checked);
        setCheck4(e.target.checked);
    }

    const NextButton = () => {
        if(allowNext){
            navigate('/shop');
        }
    }

    useEffect(() => {
        if(!cookies.user){
            requestUser();
        }

        if(check1 && check2 && check3){
            setAllowNext(true);
        }else{
            setAllowNext(false);
        }

    }, [check1, check2, check3, check4])

    let KAKAO_CODE = new URL(window.location.href).searchParams.get('code');
    const requestUser = async () => {
        const res = await fetch(process.env.REACT_APP_SERVER_URL + "/oauth", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                "code": KAKAO_CODE,
                "redirectUri": "https://store.pigstore.co.kr/oauth"
            }),
        });
        const data = await res.json();
        
        setCookie('user', data.USER_ID, {path: '/'})

        if(res.status === 200)
            navigate('/shop');
        else
            setIsRegistered(true);
    }

    return(
        !isRegistered ? <></>:
        <div>
            <header className="login">
                <a href="#" className="btn-prev"><span>이전</span></a>
            </header>
            <section className="join">
                <div>
                    <h1>피그팜 서비스 이용약관에 동의해주세요.</h1>
                    
                    <div className="form-checkbox border-bottom">
                        <input type="checkbox" id="check1" className="check1" checked={allCheck} onChange={AllCheckButton}/>
                        <label htmlFor="check1"></label><p className="label">모두 동의 (선택 정보 포함)</p>
                    </div>
                    <div>
                        <div className="form-checkbox">
                            <input type="checkbox" id="check2" className="check2" checked={check1}  onChange={()=>setCheck1(!check1)}/>
                            <label htmlFor="check2"></label><p className="label">[필수] 만 14세 이상</p>
                        </div>
                        <div className="form-checkbox d-flex">
                            <input type="checkbox" id="check3" className="check2" checked={check2} onChange={()=>setCheck2(!check2)}/>
                            <label htmlFor="check3"></label><p className="label">[필수] 이용약관 동의</p>
                            <a >보기</a>
                        </div>
                        <div className="form-checkbox d-flex">
                            <input type="checkbox" id="check4" className="check2" checked={check3}
                            onChange={()=>setCheck3(!check3)}/>
                            <label htmlFor="check4"></label><p className="label">[필수] 개인정보 처리방침 동의</p>
                            <a >보기</a>
                        </div>
                        <div className="form-checkbox d-flex">
                            <input type="checkbox" id="check5" className="check2" checked={check4}
                            onChange={()=>setCheck4(!check4)}/>
                            <label htmlFor="check5"></label><p className="label">[선택] 광고성 정보 수신 및 마케팅 활용 동의</p>
                            <a >보기</a>
                        </div>
                    </div>
                </div>
                <button className={allowNext ? "btn btn-pink" : "btn btn-gray"} onClick={NextButton}>동의하고 가입하기</button>
            </section>
        </div>
    );
}

export default Kakao;