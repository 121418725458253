export interface Question {
    bool : Boolean;
}

export interface QuestionAction {
    type: string;
    payload: Question | null;
}

const QuestionReducer = (state : Question | null = null, action: QuestionAction) : Question | null => {
    switch (action.type) {
        case 'SET_QUESTION':
            return action.payload;
        default:
            return state;
    }
}

export default QuestionReducer;