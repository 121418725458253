import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Swiper, SwiperRef, SwiperSlide, useSwiper } from "swiper/react";
import { Navigation, Pagination } from "swiper";
import imgFunding from "../../images/img_funding.png";
import { useSelector } from "react-redux";
import { addComma } from "../../utils/StringFormat";
import ReviewPreview from "./ReivewPreview";
import exImg from "../../images/ex_img.jpg";
import exImg2 from "../../images/ex_img02.jpg";
import starPoint from "../../images/star_point.png";
import starPointHalf from "../../images/star_point_half.png";
import icoCart from "../../images/ico_cart.png";
import icoShare from "../../images/ico_share.png";
import arrowDown from "../../images/arrow_down.png";
import arrowUp from "../../images/arrow_up.png";
import arrowRightBK from "../../images/arrow_right_bk.png";
import ReactModal from "react-modal";
import { useDispatch } from "react-redux";
import { GetImages, GetThumbnail } from "../../utils/ImageHandler";

const ShopDetail = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const user = useSelector((state: any) => state.user);
    const productID = useSelector((state: any) => state.shopSelect);
    let isQuestion = useSelector((state: any) => state.question);
    const [productInfo, setProductInfo] = useState<any>();
    const [reviews, setReviews] = useState<any>([]);
    let [imageInfo, setImageInfo] = useState<any>([]);
    let [imageOpen, setImageOpen] = useState<boolean>(false);
    let [showModal, setShowModal] = useState<boolean>(false);
    let [cartModal, setCartModal] = useState<boolean>(false);

    const scrollFix = () => {
        var lastScrollTop = 0;
        var delta = 5;
        var fixBox: HTMLDivElement = document.querySelector('.top')!;
        var fixBoxHeight = fixBox?.offsetHeight;
        var didScroll: boolean;
        
        
        //스크롤 이벤트 
        window.onscroll = function(e) {
            didScroll = true;
        };

        //0.25초마다 스크롤 여부 체크하여 스크롤 중이면 hasScrolled() 호출
        setInterval(function(){
            if(didScroll){
                hasScrolled();
                didScroll = false;
            }
        }, 250);

        function hasScrolled(){
            var nowScrollTop = window.scrollY; //얼마나 스크롤 했는지 반환
            if(Math.abs(lastScrollTop - nowScrollTop) <= delta){
                return;
            }
            if(nowScrollTop > lastScrollTop && nowScrollTop > fixBoxHeight){
                //Scroll down
                fixBox?.classList.add('scroll-top');
            }
            else if(nowScrollTop < delta){
                //Scroll up
                fixBox?.classList.remove('scroll-top');
            }
           
            lastScrollTop = nowScrollTop;
        }
    }

    const OpenDetail = (e: any) => {
        var productView: HTMLDivElement = document.querySelector('.product')!;
        productView?.classList.add('open');
        e.preventDefault();
        setImageOpen(!imageOpen);

        if(imageOpen){
            productView?.classList.remove('open');
        }
    }

    const GetInfo = async () => {
        const res = await fetch(process.env.REACT_APP_SERVER_URL + `/shop/detail/${productID.productID}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        })
        const data = await res.json();
        setProductInfo(data[0]);
    }

    const GetReview = async () => {
        const res = await fetch(process.env.REACT_APP_SERVER_URL + `/shop/review/${productID.productID}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        })
        const data = await res.json();
        setReviews(data);
    }

    const AddToCart = async () => {
        const res = await fetch(process.env.REACT_APP_SERVER_URL + "/cart/add", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                productID: productID.productID,
                farmID : productInfo.FARM_ID,
                userID : user.USER_ID
            })
        });
        await res.json();

        setCartModal(true);
        setTimeout(() => {
            setCartModal(false);
        }, 2000);

    };

    useLayoutEffect(() => {
        scrollFix();
        GetInfo();
        GetReview();
        GetImages("TB_ORDER_PRODUCT", productID.productID).then((res: any) => setImageInfo(res));

        if(isQuestion.bool === true){
            setShowModal(true);
            setTimeout(() => {
                dispatch({ type: "SET_QUESTION", payload: {bool: false} });
                setShowModal(false);
            }, 2000);
        }
    }, []);



    const ImageDrawer = () => {

        return(
            <Swiper
                className="headerSwiper" 
                modules={[Navigation, Pagination]}
                observer={true}
                observeParents={true}
                pagination={
                    {
                        el: ".headerSwiper .swiper-pagination",
                        type: "fraction"
                    }
                }
                navigation={
                    {
                        nextEl: ".swiper-button-next",
                        prevEl: ".swiper-button-prev"
                    }
                }>
                    {imageInfo.map((item: any) => (
                        <SwiperSlide className="swiper-slide" key={item.IMG_ID}>
                            <img src={item?.SERVER_IMG_PATH} alt="" />
                        </SwiperSlide>
                    ))}
                    <div className="swiper-pagination"></div>
            </Swiper>
        )
    }
    
    
    function ClosedReviews () {
        return (
            <>
            <div className="slide-wrap review-img">
                <Swiper className="reviewSwiper" 
                    modules={[Pagination]}
                    pagination={
                        {
                            el: ".reviewSwiper .swiper-pagination",
                            clickable: true,
                        }
                    }
                    spaceBetween={10}
                    slidesPerView={"auto"}
                    >
                    
                    <SwiperSlide className="swiper-slide">
                        <img src={imgFunding} alt="" />
                    </SwiperSlide>
                    <SwiperSlide className="swiper-slide">
                        <img src={imgFunding} alt="" />
                    </SwiperSlide>
                    <SwiperSlide className="swiper-slide">
                        <img src={imgFunding} alt="" />
                    </SwiperSlide>
                    <SwiperSlide className="swiper-slide">
                        <img src={imgFunding} alt="" />
                    </SwiperSlide>
                    <SwiperSlide className="swiper-slide">
                        <img src={imgFunding} alt="" />
                    </SwiperSlide>
                    <SwiperSlide className="swiper-slide">
                        <img src={imgFunding} alt="" />
                    </SwiperSlide>
                </Swiper>
            </div>
            
            <div className="review-wrap">
                {reviews.map((item: any) => (
                    <ReviewPreview {...item} key={item.REVIEW_ID}/>
                ))}
                <div className="">
                    <a className="btn btn-pink" onClick={()=>navigate('/reviews')}>후기 더보기</a>
                </div>
            </div>
            </>
        )
    }

    return (
        <div>
            <div> 
                <header className="sub shop">
                    <div className="top">
                        <Link to ={'/shop'} className="btn-prev"><span>이전</span></Link>
                        <h1>{productInfo?.PRODUCT_NM}</h1>
                        <Link to ={'/cart'} className="btn-cart"><span>장바구니</span></Link>
                    </div>
                    {ImageDrawer()}
                </header>
            
                <section>
                    <div>
                        <div className="product-header">
                            <div className="d-flex space-between align-center mb-0">
                                <h2 className="mb-0"><span className="d-block fs-14 medium">{productInfo?.FARM_NM}</span>{productInfo?.PRODUCT_NM}</h2>
                                <div>
                                    <a className="ico-share"><img src={icoShare} alt="" /></a>
                                </div>
                            </div>
                            <div>
                                <div>
                                    <span className="star-point"></span><span className="star-point"></span><span className="star-point"></span><span className="star-point"></span><span className="star-point-half"></span> 
                                    <span>{productInfo?.RATE}</span>｜<span>10건</span>
                                </div>
                                <div className="fs-21 bold mt-20">
                                    <span className="color-red">{productInfo?.DISCOUNT_RATE}%</span> 
                                    <span>{addComma(productInfo?.DISCOUNT_PRICE)}원</span> 
                                    <span className="fs-14 normal text-line vertical-middle color-gray ml-10">{addComma(productInfo?.PRICE)}원</span>
                                </div>
                            </div>
                        </div>
                        <div className="farm-detail deliver">
                            <dl>
                                <dt className="normal">배송</dt>
                                <dd>
                                    <p>{addComma(productInfo?.DELIVERY_PRICE)}원 (30,000원 이상 무료배송)</p>
                                    <p>일반택배</p>
                                    <p className="fs-12 color-gray">제주도/도서산간 5,000원</p>
                                    <p className="arrival">결제 후 2~5일내 도착예정</p>
                                </dd>
                            </dl>
                        </div>
                        
                        <div className="scroll-tab">
                            <div id="fixedMenu" className="fixed-menu">
                                <ul>
                                    <li><a href="#smenu01" className="smenu01 active">상품정보</a></li>
                                    <li><a href="#smenu02" className="smenu02">필수정보</a></li>
                                    <li><a href="#smenu03" className="smenu03">후기<span>({reviews.length})</span></a></li>
                                    <li><a href="#smenu04" className="smenu04">문의</a></li>
                                </ul>
                            </div>
                            
                            <div id="smenu01" className="farm-detail product">
                                <p className="mb-10"><img src={exImg} alt="" style={{width: "100%"}} /></p>
                                <p><img src={exImg2} alt="" style={{width: "100%"}} /></p>
                                <div className="product-more" onClick={OpenDetail}>
                                    <a className="btn btn-pink mt-10">{imageOpen ? "닫기" : "펼치기"}<img src={imageOpen ? arrowUp : arrowDown} alt="" style={{width: "14px"}} /></a>
                                </div>
                            </div>
                            
                            <div id="smenu02" className="farm-detail">
                                <div className="d-flex space-between align-center">
                                    <p className="fs-15 bold">필수정보</p> 
                                    <a   className="btn-detail"><img src={arrowUp} alt="" /></a>
                                </div>
                                <div className="info">
                                    <dl>
                                        <dt>제품명</dt>
                                        <dd>{productInfo?.REQ_NAME}</dd>
                                    </dl>
                                    <dl>
                                        <dt>식품 유형</dt>
                                        <dd>{productInfo?.REQ_CATEGORY}</dd>
                                    </dl>
                                    <dl>
                                        <dt>생산자 및 소재지</dt>
                                        <dd>{productInfo?.REQ_MAKER_NM}</dd>
                                    </dl>
                                    <dl>
                                        <dt>제조연월일, 유통기한 또는 품질 유지기한</dt>
                                        <dd>{productInfo?.REQ_BEST_BEFORE}</dd>
                                    </dl>
                                    <dl>
                                        <dt>포장단위별 내용물의 용량(중량), 수량</dt>
                                        <dd>{productInfo?.REQ_WEIGHT}</dd>
                                    </dl>
                                    <dl>
                                        <dt>원재료명 및 함량</dt>
                                        <dd>{productInfo?.REQ_MATERIAL}</dd>
                                    </dl>
                                    <dl>
                                        <dt>영양성분</dt>
                                        <dd>{productInfo?.REQ_NUTRIENT}</dd>
                                    </dl>
                                    <dl>
                                        <dt>유전자변형식품에 해당하는 경우의 표시</dt>
                                        <dd>{productInfo?.REQ_GMO}</dd>
                                    </dl>
                                </div>
                            </div>
                            <div id="smenu03" className="farm-detail">
                                <div className="d-flex space-between align-center">
                                    <p className="fs-15 bold">배송안내</p>
                                    <a className="btn-detail"><img src={arrowUp} alt="" /></a>
                                </div>
                                <div className="info">
                                    <dl>
                                        <dt>배송 업체</dt>
                                        <dd>CJ 대한통운(1544-7772)</dd>
                                    </dl>
                                    <dl>
                                        <dt>배송 지역</dt>
                                        <dd>대한민국 전 지역</dd>
                                    </dl>
                                    <dl>
                                        <dt>배송 비용</dt>
                                        <dd>3,000원 / 구매 금액 50,000원 이상 시 무료배송 (도서산간 지역 별도 추가 금액 발생)</dd>
                                    </dl>
                                    <dl>
                                        <dt>배송 기간</dt>
                                        <dd>주말 및 공휴일 제외 2-5일</dd>
                                    </dl>
                                    <dl>
                                        <dt>유의 사항</dt>
                                        <dd>주문 폭주 및 공급 사정으로 인하여 지연 및 품절이 발생될 수 있습니다.
                                            <br />
                                        기본 배송기간 이상 소요되는 상품이거나, 품절 상품은 개별 연락을 드립니다.
                                        </dd>
                                    </dl>
                                </div>
                            </div>
                            <div id="smenu04" className="farm-detail">
                                <div className="d-flex space-between align-center">
                                    <p className="fs-15 bold">교환 및 반품 정보</p>
                                    <a className="btn-detail"><img src={arrowUp} alt="" /></a>
                                </div>
                                <div className="info">
                                    <dl>
                                        <dt>신청 방법</dt>
                                        <dd>상품을 수령하신 날로부터 7일 이내로 메신저 및 홈페이지 문의게시판 접수</dd>
                                    </dl>
                                    <dl>
                                        <dt>배송 비용</dt>
                                        <dd>단순 변심은 왕복 택배비 6,000원</dd>
                                    </dl>
                                    <dl>
                                        <dt>반품 주소</dt>
                                        <dd>경기도 안양시 동안구 시민대로 230, 디동 3452호</dd>
                                    </dl>
                                    <dl>
                                        <dt>유의 사항</dt>
                                        <dd>단순 변심의 경우 수령일로부터 7일 이내까지 교환 및 반품이 가능합니다. (교환/반품비 고객 부담)
                                            <br />
                                        상품 하자, 오배송의 경우 수령일로부터 7일 이내 교환 및 반품이 가능합니다.(교환/반품비 무료)
                                            <br />
                                        제품 특성상 단순 변심, 부주의에 의한 제품 손상 및 파손, 사용 및 개봉한 경우 교환/반품이 불가합니다.
                                        </dd>
                                    </dl>
                                </div>
                            </div>
                            <div id="smenu05" className="farm-detail pd-0">
                                <div className="d-flex space-between align-center pd-22">
                                    <p className="fs-15 bold">후기({reviews.length})</p> 
                                    <a   className="btn-detail"><img src={arrowUp} alt="" /></a>
                                </div>
                                
                                <div>
                                    <div className="point-wrap">
                                        <div className=" ">
                                            <div>
                                                <img src={starPoint} alt="" />
                                                <img src={starPoint} alt="" />
                                                <img src={starPoint} alt="" />
                                                <img src={starPoint} alt="" />
                                                <img src={starPointHalf} alt="" />
                                                <p className="fs-18 bold">4.5 <span className="color-gray">/ 5</span> <span className="fs-14 color-gray medium">({reviews.length})</span></p>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div className="slide-wrap review-img">
                                        <Swiper className="reviewSwiper" 
                                            modules={[Pagination]}
                                            pagination={
                                                {
                                                    el: ".reviewSwiper .swiper-pagination",
                                                    clickable: true,
                                                }
                                            }
                                            spaceBetween={10}
                                            slidesPerView={"auto"}
                                            >
                                            {reviews.map((item: any) => (
                                                <SwiperSlide className="swiper-slide" key={item.REVIEW_ID}>
                                                    <img src={"mosigganege"} alt="" />
                                                </SwiperSlide>
                                                    ))}
                                            <SwiperSlide className="swiper-slide">
                                                <img src={imgFunding} alt="" />
                                            </SwiperSlide>
                                            <SwiperSlide className="swiper-slide">
                                                <img src={imgFunding} alt="" />
                                            </SwiperSlide>
                                            <SwiperSlide className="swiper-slide">
                                                <img src={imgFunding} alt="" />
                                            </SwiperSlide>
                                            <SwiperSlide className="swiper-slide">
                                                <img src={imgFunding} alt="" />
                                            </SwiperSlide>
                                            <SwiperSlide className="swiper-slide">
                                                <img src={imgFunding} alt="" />
                                            </SwiperSlide>
                                            <SwiperSlide className="swiper-slide">
                                                <img src={imgFunding} alt="" />
                                            </SwiperSlide>
                                        </Swiper>
                                    </div>
                                </div>
                                
                                <div className="review-wrap">
                                    {reviews.map((item: any) => (
                                        <ReviewPreview {...item} key={item.REVIEW_ID}/>
                                    ))}
                                    <div className="">
                                        <a className="btn btn-pink" onClick={()=>navigate('/reviews')}>후기 더보기</a>
                                    </div>
                                </div>
                            </div>
                            
                            <div id="smenu06" className="">
                                <div className="d-flex space-between align-center pd-22">
                                    <p className="fs-15 bold">문의</p> 
                                    <a className="btn-detail" onClick={()=>navigate('/inquery/notice')}><img src={arrowRightBK} alt="" /></a>
                                </div>
                                <div className="pd-22">
                                    <div className="d-flex">
                                        <p className="pd-20">
                                            <a className="ico-cart" onClick={()=>navigate('/cart')}><img src={icoCart} alt="" /></a>
                                        </p>
                                        <a className="btn btn-pink" onClick={AddToCart}>구매하기</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            
            <ReactModal isOpen={showModal} onRequestClose={() => setShowModal(false)} className="modal" overlayClassName="overlay" ariaHideApp={false}>
                <div className="balance-alert">
                    문의가 등록되었습니다
                </div>
            </ReactModal>

            <ReactModal isOpen={cartModal} onRequestClose={() => setCartModal(false)} className="modal" overlayClassName="overlay" ariaHideApp={false}>
                <div className="balance-alert">
                    장바구니에 추가되었습니다.
                </div>
            </ReactModal>
            
            </div>
        </div>
    );
};

export default ShopDetail;
