export interface DataState {
    farmID: string | null;
}

const initialState: DataState = {
    farmID : null
};

const FarmSelector = (state = initialState, action: any) => {
    switch (action.type) {
        case 'SELECT_FARM':
            return {
                ...state,
                farmID: action.payload
            };
        default:
            return state;
    }
}

export default FarmSelector;