export interface Fund {
    BREED_COST: number;
    CREATE_DT: string;
    CURRENT_AMOUNT: number;
    END_DT: string;
    FARM_ID: number;
    FUND_RATE: number;
    FUNDING_ID: number;
    MIN_AMOUNT: number;
    PIG_AGE: number;
    PIG_GENE: number;
    PIG_ID: number;
    PIG_NM: string;
    PIG_WEIGHT: number;
    PIGLET_COST: number;
    START_DT: string;
    TARGET_AMOUNT: number;
}

export interface FundAction{
    type: string;
    payload: Fund[] | null;
}

export const FundReducer = (state: Fund[] | null = null, action: FundAction) : Fund[] | null => {
    switch (action.type) {
        case 'SET_FUND':
            return action.payload;
        default:
            return state;
    }
}