import React, { FC, useEffect, useState } from "react";
import 'jquery-ui/ui/widgets/tabs.js';
import ShopPreview from "../components/shop/ShopPreview";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useCookies } from "react-cookie";
import { GetUserInfo } from "../utils/User";

const $ = require("jquery");

interface ShopProps {
    setActiveLink: (link: string) => void;
    setIsActive: (active: boolean) => void;
}

const Shop: FC<ShopProps> = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [cookies, setCookie, removeCookie] = useCookies(['user']);

    let arr: Array<any> = [];
    const [products, setProducts] = useState(arr);
    const [filteredProducts, setFilteredProducts] = useState(arr);
    const [sortValue, setSortValue] = useState("ascPrice");

    const GetProducts = async () => {
        const res = await fetch(process.env.REACT_APP_SERVER_URL + `/shopInit`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        })
        const data = await res.json();
        setProducts(data);
    }

    const handleCategoryChange = (e: React.MouseEvent<HTMLLIElement>) => {
        const category = e.currentTarget.value;
        if (category == 0) {
            setFilteredProducts(products);
        } else if (category == 1) {
            setFilteredProducts(products.filter((product) => product.CATEGORY === "삼겹살"));
        } else if (category == 2) {
            setFilteredProducts(products.filter((product) => product.CATEGORY === "목살"));
        } else if (category == 3) {
            setFilteredProducts(products.filter((product) => product.CATEGORY === "앞다리살"));
        } else if (category == 4) {
            setFilteredProducts(products.filter((product) => product.CATEGORY === "특수부위"));
        }
    }

    const handleSortChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const sortValue = e.target.value;
        const sortedProducts = [...products];
        if (sortValue === "descRate") {
            sortedProducts.sort((a, b) => b.RATE - a.RATE);
        } else if (sortValue === "ascRate") {
            sortedProducts.sort((a, b) => a.RATE - b.RATE);
        } else if (sortValue === "descPrice") {
            sortedProducts.sort((a, b) => b.PRICE - a.PRICE);
        } else if (sortValue === "ascPrice") {
            sortedProducts.sort((a, b) => a.PRICE - b.PRICE);
        }
        setProducts(sortedProducts);
    }

    useEffect(() => {
        props.setActiveLink("menu3");
        props.setIsActive(true);
        GetProducts();
        requestUser();
        dispatch({ type: "SET_QUESTION", payload: {bool: false} });
        
        $(function() {
            $("#sub-tabs").tabs();
        });

        return () => {
            props.setIsActive(false);
        }
    }, []);


    const requestUser = async () => {
        let userID = cookies.user;

        GetUserInfo(cookies.user).then(res => {
            dispatch({ type: "SET_USER", payload: res });
        });
        
        await RequestWishlist(userID);
    }

    const RequestWishlist = async (userID: number) => {
        const response = await fetch(process.env.REACT_APP_SERVER_URL + "/wishlist", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                "userID": userID,
            }),
        });
        const data = await response.json();
        dispatch({ type: "SET_WISHLIST", payload: data });
    }

    const Footer = () => {
        return (
            <footer style={{paddingTop: "30px", paddingBottom: "80px"}}>
                <div className="footer-wrap" style={{marginLeft: "35px"}}>
                     <div className="footer-info">
                        <p>상호명: 주식회사 제트플로우</p>
                        <p>대표자명: 김혜진</p>
                        <p>사업자등록번호: 559-81-02173</p>
                        <p>통신판매업신고번호: 2023-안양동안-0647호</p>
                        <p>사업장주소: 경기도 안양시 동안구 시민대로 230, 디동 3452호</p>
                        <p>전화번호: 010-8735-8414 이메일: hj.kim@zflow.co.kr </p>
                        <p>© 2023 ZFLOW. All rights reserved.</p>
                    </div>
                </div>
            </footer>
        )
    }


    return (
        <div>
            <header className="sub">
                <h1 className="fs-20 text-left">스토어</h1>
                <div className="right">
                    <a className="btn-cart btn-ico" onClick={()=>navigate('/cart')}><span>장바구니</span></a>
                    <a className="btn-my btn-ico" onClick={()=>navigate('/myshopping')}><span>마이페이지</span></a>
                </div>
            </header>
            <section>
                <div className="main-wrap">
                    <div id="sub-tabs" className="tabs sub-tabs">
                        <ul>
                            <li key={"all"} value={0} onClick={handleCategoryChange}>
                                <a href="#subtab-1">전체</a></li>
                            <li key={"삼겹살"} value={1} onClick={handleCategoryChange}>
                                <a href="#subtab-2">삼겹살</a></li>
                            <li key={"목살"} value={2} onClick={handleCategoryChange}>
                                <a href="#subtab-2">목살</a></li>
                            <li key={"앞다리살"} value={3} onClick={handleCategoryChange}>
                                <a href="#subtab-2">앞다리살</a></li>
                            <li key={"특수부위"} value={4} onClick={handleCategoryChange}>
                                <a href="#subtab-2">특수부위</a></li>
                        </ul>
                        <div id="subtab-1">
                            <div className="sort-select">
                                <select name="" id="" title="dasdsa" defaultValue={sortValue} onChange={handleSortChange}>
                                    <option key="descPrice" value="descPrice">높은 가격순</option>
                                    <option key="ascPrice" value="ascPrice">낮은 가격순</option>
                                    <option key="descRate" value="descRate">높은 평점순</option>
                                    <option key="ascRate" value="ascRate">낮은 평점순</option>
                                </select>
                            </div>
                            <div className="list-group list-block">
                                {products.map((item: any) => (
                                    <ShopPreview {...item} key={item.PRODUCT_ID} />
                                ))}
                            </div>
                        </div>
                        <div id="subtab-2">
                            <div className="list-group list-block">
                                {filteredProducts.map((item: any) => (
                                    <ShopPreview {...item} key={item.PRODUCT_ID} />
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    );
};

export default Shop;