import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDaumPostcodePopup } from "react-daum-postcode";
import { useSelector } from "react-redux";

const CURRENT_URL = 'https://t1.daumcdn.net/mapjsapi/bundle/postcode/prod/postcode.v2.js'

interface Juso {
    buildingName: string;
    zonecode: string;
    roadAddress: string; 
}

export default function ShippingAdd () {
    let navigate = useNavigate();
    let [juso, setJuso] = useState<Juso>();
    let [DEFAULT_YN, setDEFAULT_YN] = useState<boolean>(false);
    let [ADDR_NM, setADDR_NM] = useState<string>("");
    let [ADDRESS, setADDRESS] = useState<string>("");
    let [NAME_TO, setNAME_TO] = useState<string>("");
    let [MOBILE, setMOBILE] = useState<string>("");
    let [isComplete, setIsComplete] = useState<boolean>(false);
    let user = useSelector((state: any) => state.user);

    const open = useDaumPostcodePopup(CURRENT_URL); //scriptUrl {String} : Daum 우편번호 스크립트 주소
    
    const handleComplete = (data: any) => {
        setJuso(data);
    };
    
    const handleClick = () => {
        open({ onComplete: handleComplete });
    };

    function handleInput (e : any) {
        if (e.target.name === "ADDR_NM") {
            setADDR_NM(e.target.value);
        } else if (e.target.name === "NAME_TO") {
            setNAME_TO(e.target.value);
        } else if (e.target.name === "MOBILE") {
            setMOBILE(e.target.value);
        } else if (e.target.name === "ADDRESS") {
            setADDRESS(e.target.value);
        }

        if (ADDR_NM !== "" && NAME_TO !== "" && MOBILE !== "" && ADDRESS !== "") {
            setIsComplete(true);
        } else {
            setIsComplete(false);
        }
    }

    async function saveAddress () {
        if (!isComplete) return;
        
        let address = {
            USER_ID: user.USER_ID,
            DEFAULT_YN: DEFAULT_YN,
            ADDR_NM: ADDR_NM,
            NAME_TO: NAME_TO,
            MOBILE: MOBILE,
            ZONECODE: juso?.zonecode,
            ROAD_ADDR: juso?.roadAddress,
            ADDRESS: ADDRESS
        }

        console.log(address);

        await fetch(process.env.REACT_APP_SERVER_URL + `/address/add`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(address),
        })
        .then(res => res.json())
        .then(()=>navigate(-1))
    } 

    return (
        <>
        <header className="sub">
            <a className="btn-prev" onClick={()=>navigate(-1)}><span>이전</span></a>
            <h1>배송지 추가</h1>
        </header>
        
        <section className="full-sub">
            <div>
                <div className="shop-detail bd-0">
                    <div className="inner">
                        <dl>
                            <dt>별칭</dt>
                            <dd><input type="text" name="ADDR_NM" onChange={handleInput}/></dd>
                        </dl>
                        <dl>
                            <dt>받는사람</dt>
                            <dd><input type="text" name="NAME_TO" onChange={handleInput}/></dd>
                        </dl>
                        <dl>
                            <dt>전화번호</dt>
                            <dd><input type="text" name="MOBILE" onChange={handleInput} placeholder="-없이 입력해주세요" /></dd>
                        </dl>
                        <dl>
                        <dt>주소</dt>
                        <dd>
                            <div className="">
                                <div className="d-flex space-between">
                                    <p><a className="btn-find" onClick={handleClick}>주소찾기</a></p>
                                    <p><input type="text" className="disabled" defaultValue={juso?.zonecode}/></p>
                                </div>
                            </div>
                            <div>
                                <p><input type="text" className="disabled" defaultValue={juso?.roadAddress}/></p>
                                <p><input type="text" name="ADDRESS" onChange={handleInput} placeholder="상세주소 입력" /></p>
                            </div>
                            <div >
                                <input type="checkbox" id="check1" className="check1" onClick={()=>setDEFAULT_YN(!DEFAULT_YN)}/>
                                <label htmlFor="check1"></label>
                                <p className="fs-15 label">기본 배송지로 저장</p> 
                            </div>
                        </dd>
                    </dl>
                    </div>
                </div>
            </div>
            
            <div className="btn-area">
                <a className={isComplete? "btn btn-pink" : "btn btn-gray"} onClick={saveAddress}>저장</a>
            </div>
                
        </section>
        </>
    )
}