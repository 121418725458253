export const GetThumbnail = async (table: string, id: number) => {
    const res = await fetch(process.env.REACT_APP_SERVER_URL + `/imageinfo/${table}/${id}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
    });
    const data = await res.json();

    if (data.length === 0) return null;

    return process.env.REACT_APP_SERVER_URL + data[0].SERVER_IMG_PATH;
}

export const GetImages = async (table: string, id: number) => {
    const res = await fetch(process.env.REACT_APP_SERVER_URL + `/imageinfo/${table}/${id}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
    })
    const data = await res.json();

    if (data.length === 0) return null;

    data.sort((a: any, b: any) => { return a.IMAGE_ORDER - b.IMAGE_ORDER });

    data.forEach((element: any) => {
        element.SERVER_IMG_PATH = process.env.REACT_APP_SERVER_URL + element.SERVER_IMG_PATH;
    });

    return data;
}