import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { MobileFormat } from "../../utils/StringFormat";

interface Address {
    ADDR_ID: number;
    USER_ID: number;
    DEFAULT_YN: string;
    ADDR_NM: string;
    NAME_TO: string;
    MOBILE: string;
    ROAD_ADDR: string;
    ADDRESS: string;
}

export default function ShippingList () {
    let navigate = useNavigate();
    let [addressList, setAddressList] = useState<any>([]);
    let user = useSelector((state: any) => state.user);
    let [defaultAddress, setDefaultAddress] = useState<Address>();

    async function getAddressList () {
        let res = await fetch(process.env.REACT_APP_SERVER_URL + `/address/${user.USER_ID}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                },
            });

        let data = await res.json();

        if(data.length === 0) {
            setDefaultAddress({
                ADDR_ID: 0,
                USER_ID: 0,
                DEFAULT_YN: "false",
                ADDR_NM: "배송지를 등록해주세요",
                NAME_TO: "",
                MOBILE: "",
                ROAD_ADDR: "",
                ADDRESS: "",
            });
            return;
        }


        setAddressList(data);

        data.forEach((item: Address) => {
            if (item.DEFAULT_YN === "true") {
                setDefaultAddress(item);
            }
        });
    }

    useEffect(() => {
        getAddressList();
    }, []);

    function DefaultAddress () {

        return (
            <div className="shop-detail address-top">
                <div className="address-box">
                    <div className="address-title">
                        <p className="fs-18 bold d-inline-block mr-10">{defaultAddress?.ADDR_NM}</p> 
                        <a className="btn-address">기본배송지</a>
                    </div>
                    <div className="inner">
                        <p>{`${defaultAddress?.ROAD_ADDR} ${defaultAddress?.ADDRESS}`}</p>
                        <p className="color-gray">{defaultAddress?.NAME_TO} {MobileFormat(defaultAddress?.MOBILE)}</p>
                    </div>
                </div>
            </div>
        )
    }

    async function postDefaultAddressDB (props: Address) {
        let res = await fetch(process.env.REACT_APP_SERVER_URL + `/address/setDefault/`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                },
            body: JSON.stringify({
                USER_ID: user.USER_ID,
                ADDR_ID: props.ADDR_ID,
            })
        });

        await res.json();
    }

    async function deleteAddressDB (addrID: number) {
        let res = await fetch(process.env.REACT_APP_SERVER_URL + `/address/delete/`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                },
            body: JSON.stringify({
                USER_ID: user.USER_ID,
                ADDR_ID: addrID,
            })
        });

        let data = await res.json();
        setAddressList(data);
    }

    function editAddress (address: Address) {
        navigate('/shop/ship/edit', {state: address.ADDR_ID});
    }


    function Address (props: Address) {

        function changeDefault () {
            setDefaultAddress(props);
            postDefaultAddressDB(props);
        }

        return (
            <div className="shop-detail">
                <div className="address-box">
                    <div className="address-title">
                        <p className="fs-18 bold d-inline-block mr-10">{props.ADDR_NM}</p> 
                    </div>
                    <div className="inner">
                        <p>{`${props.ROAD_ADDR} ${props.ADDRESS}`}</p>
                        <p className="color-gray">{props.NAME_TO} {MobileFormat(props.MOBILE)}</p>
                        <div className="btn-area">
                            <div>
                                <a className="btn" onClick={()=>deleteAddressDB(props.ADDR_ID)}>삭제</a>
                                <a className="btn" onClick={()=>editAddress(props)}>수정</a>
                            </div>
                            <a className="btn btn-black" onClick={changeDefault}>선택</a>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return(
        <>
            <header className="sub">
                <a className="btn-prev" onClick={()=>navigate(-1)}><span>이전</span></a>
                <h1>배송지 관리</h1>
            </header>
            
            <section className="bg-gray full-sub">
                <div>
                    <DefaultAddress />

                    {addressList.map((item: Address) => (
                        <Address {...item} key={item.ADDR_ID}/>
                    ))}

                </div>
                
                <div className="btn-area">
                    <a className="btn btn-pink" onClick={()=>navigate('/shop/ship/add')}>배송지 추가</a>
                </div>
                    
            </section>
        </>
    )
}