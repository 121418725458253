export interface ProductID {
    productID: number;
}

export interface ProductIDAction {
    type: string;
    payload: ProductID | null;
}

const ShopSelector = (state : ProductID | null = null, action: ProductIDAction) : ProductID | null => {
    switch (action.type) {
        case 'SELECT_SHOP':
            return action.payload;
        default:
            return state;
    }
}

export default ShopSelector;