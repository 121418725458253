export const GetUserInfo = async (userID : number) => {
    const response = await fetch(process.env.REACT_APP_SERVER_URL + `/user/${userID}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        }
    });
    const data = await response.json();
    
    return data[0];
}