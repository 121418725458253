import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { addComma } from "../../utils/StringFormat";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

interface CartTotalProps {
    farms: any[];
}

export default function CartTotal(props: CartTotalProps) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const cart = useSelector((state: any) => state.cart);

    const SumItemPrice = () => {
        let sum = 0;

        cart.items.forEach((item: any) => {
            sum += item.PRICE * item.COUNT;
        });
        return sum;
    }

    const SumItemDelivery = () => {
        if (cart.items.length === 0) return 0;
        let sum = 0;

        props.farms.forEach((farm: any) => {
            const farmItems = cart.items.filter((item: any) => item.FARM_NM === farm);
            if (farmItems.length === 0) return;
            sum += farmItems[0].DELIVERY_PRICE;
        });

        return sum;
    }

    const SumTotalPrice = () => {
        let sum = 0;
        cart.items.forEach((item: any) => {
            sum += item.DISCOUNT_PRICE * item.COUNT;
        });
        return sum;
    }

    const SumItemDiscount = () => {
        let res = SumItemPrice() - SumTotalPrice();

        return res;
    }

    useEffect(() => {

        return () => {
            let total = {
                OriginalAmount: SumItemPrice(),
                DeliveryAmount: SumItemDelivery(),
                DiscountAmount: SumItemDiscount(),
                TotalAmount: SumTotalPrice() + SumItemDelivery(),
            }
            dispatch({ type: "SET_BILL", payload: total });
        }
    }, []);


    return (
        <div>
            <div className="pd-22">
                <div className="price-list">
                    <dl>
                        <dt>총 상품금액</dt>
                        <dd>{addComma(SumItemPrice())}원</dd>
                    </dl>
                    <dl>
                        <dt>총 배송비</dt>
                        <dd>+{addComma(SumItemDelivery())}원</dd>
                    </dl>
                    <dl>
                        <dt>총 할인금액</dt>
                        <dd>-{addComma(SumItemDiscount())}원</dd>
                    </dl>
                    <dl className="total-price">
                        <dt>결제금액</dt>
                        <dd>{addComma(SumTotalPrice() + SumItemDelivery())}원</dd>
                    </dl>
                </div>
                <div className="product-more">
                    <a className="btn btn-pink bold mt-10" onClick={()=>navigate('/shop/payment')}>
                        {addComma(SumTotalPrice() + SumItemDelivery())}원 주문하기</a>
                </div>
            </div>
        </div>
    );
}