export interface User {
    USER_ID: number;
    EMAIL: string;
    KAKAO_NICK: string;
    MOBILE: string;
    PIN: string;
}

export interface UserAction {
    type: string;
    payload: User | null;
}

const UserReducer = (state : User | null = null, action: UserAction) : User | null => {
    switch (action.type) {
        case 'SET_USER':
            return action.payload;
        default:
            return state;
    }
}

export default UserReducer;