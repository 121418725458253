export interface News {
    NEWS_ID: number;
}

export interface NewsAction {
    type: string;
    payload: News | null;
}

const NewsReducer = (state : News | null = null, action: NewsAction) : News | null => {
    switch (action.type) {
        case 'SELECT_NEWS':
            return action.payload;
        default:
            return state;
    }
}

export default NewsReducer;